//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDeadlines,
         getDictionary,
         getDictFLJ,
         getDictWP,
         getStateRules,
         getNewVfaDate,
         reformatRules } from '~/utils/butterUtils'
import { MS_PER_DAY, getVFAParameters } from '~/utils/VFAParameters'
import snarkdown from 'snarkdown'
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard',
  async asyncData ({ store }) {
    let state =
      store.getters['requests/getCurrent'] &&
      store.getters['requests/getCurrent'].leo
        ? store.getters['requests/getCurrent'].leo.s
        : ''
    return {
      registering: store.getters['requests/getCurrent'].isRegistered !== 'registered',
      state: state,
      submissionMethod: store.getters['requests/getCurrent'].recBallot
    }
  },
  data () {
    return {
      currentRequestStage: 'Fill & Sign'
    }
  },
  methods: {
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    startNewFPCASession () {
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$router.push( this.localePath({name: 'index'}) )
    },
    md (md) {
      return snarkdown(md)
    },
    voterDeadlineObject (elections, stateRules, dateFormat) {
      const vfaURL = 'www.votefromabroad.org'

      if (elections.length===0) {
        // Look for a set of submission options in the state rules. Start with registration.
        // If nothing in Registration, then cascade down.
        // If still no submission options, then assume voter may submit via postal mail.
        let so = 'mail'
        let sr = stateRules
        if (sr) { 
          so =sr.deliveryMethodRegistration.toLowerCase()
          if (!so) {
            so = sr.deliveryMethodBallotRequest.toLowerCase()
          }
          if (!so) {
            so =  sr.deliveryMethodBallotReturn.toLowerCase()
          }
        }
        so = so.replace(/-or-/g,"/")

        return {
          stateName: stateRules.name,
          rule: '',
          deadline: '',
          deadlineTime: '',
          note: '',
          voterTypeFullName: '',

          rule2: '',
          deadline2: '',
          deadlineTime2: '',
          note2: '',
          voterTypeFullName2: '',

          rule3: '',
          deadline3: '',
          deadlineTime3: '',
          note3: '',
          voterTypeFullName3: '',

          submissionMethod: so,
          alternateSubmissionMethod: so,
          electionDay: 'Jul-04, 1776',
          electionType: 'General',
          url: vfaURL, /* process.env.url, */
          state: 'US'
        }
      }

      let rule = elections[0].rule
      let deadline = new Date(elections[0].ruleDate)
      let deadlineTime = elections[0].ruleTime
      let note = elections[0].note
      let voterTypeFullName = elections[0].voterTypeFullName

      let rule2 = ""
      let deadline2 = ""
      let deadlineTime2 = ""
      let note2 = ""
      let voterTypeFullName2 = ""

      if (elections.length>1) {
        rule2 = elections[1].rule
        deadline2 = new Date(elections[1].ruleDate)
        deadlineTime2 = elections[1].ruleTime
        note2 = elections[1].note
        voterTypeFullName2 = elections[1].voterTypeFullName
      }

      let rule3 = ""
      let deadline3 = ""
      let deadlineTime3 = ""
      let note3 = ""
      let voterTypeFullName3 = ""

      if (elections.length>2) {
        rule3 = elections[2].rule
        deadline3 = new Date(elections[2].ruleDate)
        deadlineTime3 = elections[2].ruleTime
        note3 = elections[2].note
        voterTypeFullName3 = elections[2].voterTypeFullName
      }

      let methods =
        (elections.length < 2 || elections[0].submissionOptions.length > 2)
          ? ''
          : this.getDictWP(this.dict.E25, {
            method: elections[0].submissionOptions.join('/')
          })
      let altMethods =
        elections.length < 2 || elections[1].submissionOptions.length > 2
          ? ''
          : this.getDictWP(this.dict.E13, {
            rule: this.getDictFLJ(`request.deadlineLanguage.${elections[1].rule}`, this.dict),
            deadline: new Date(elections[1].ruleDate).toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
            deadlineTime: elections[1].ruleTime,
            method: elections[1].submissionOptions.join('/')
          })

      return {
        stateName: stateRules.name,
        rule: this.getDictFLJ(`request.deadlineLanguage.${rule}`, this.dict),
        deadline: deadline.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
        deadlineTime: (deadlineTime!=='12:00AM' && deadlineTime!=='11:59PM') ? deadlineTime+' ' : '',
        note: note ? ' ('+note+' '+this.dict.H26+' "'+voterTypeFullName+'")' : '',

        rule2: rule2 ? ' or '+this.getDictFLJ(`request.deadlineLanguage.${rule2}`, this.dict) : '',
        deadline2: deadline2 ? ' '+deadline2.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}) : '',
        deadlineTime2: deadlineTime2 ? ((deadlineTime2!=='12:00AM' && deadlineTime2!=='11:59PM') ? ' '+deadlineTime2+' ' : '') : '',
        note2: note2 ? ' ('+note2+' '+this.dict.H26+' "'+voterTypeFullName2+'")' : '',

        rule3: rule3 ? ' or '+this.getDictFLJ(`request.deadlineLanguage.${rule3}`, this.dict) : '',
        deadline3: deadline3 ? ' '+deadline3.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}) : '',
        deadlineTime3: deadlineTime3 ? ((deadlineTime3!=='12:00AM' && deadlineTime3!=='11:59PM') ? ' '+deadlineTime3+' ' : '') : '',
        note3: note3 ? ' ('+note3+' '+this.dict.H26+' "'+voterTypeFullName3+'")' : '',

        submissionMethod: methods,
        alternateSubmissionMethod: altMethods,
        electionDay: new Date(elections[0].electionDate).toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
        electionType: elections[0].electionType,
        url: vfaURL, /* process.env.url, */
        state: elections[0].state
      }
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    instructionsObject () {
      let votState = this.votState
      return {
        leoName: this.leoName || '',
        transmitOpts: this.transmitOpts,
        default: this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        }),
        specialDeadline: this.stateRules.dashboardspecialdeadline
          ? this.stateRules.dashboardspecialdeadline
          : this.dict.E16
      }
    },
    transmitInstructions () {
      if (this.stateRules.dashboardspecialdeadline) {
        return this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        })
      } else if (this.isStateSpecial) {
        return this.getDictWP(this.dict.E24,
          Object.assign({}, this.instructionsObject, { specialDeadline: '' }, {stateSpecial: this.stateRules.dashboardspecial})
        )
      } else {
        return this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        })
      }
    },
    transmitRules () {
      if (this.stateRules.dashboardspecialdeadline) {
        return this.getDictWP(this.dict.E24,
          Object.assign({}, this.instructionsObject, { default: '' }, {stateSpecial: this.stateRules.dashboardspecial})
        )
      } else {
        return this.stateRules &&
          this.stateRules.fpcaSubmitOptionsRequest.includes('Email')
          ? this.dict.E16
          : ''
      }
    },
    formSubmitted () {
      return this.getDictWP(this.dict.E18, {
        alsoVoterRegistration:
          this.isRegistered === 'registered'
            ? ''
            : this.dict.E12,
        leoName: this.leoName,
        specialRules: this.stateRules.dashboardspecialdeadline
          ? this.capitalizeFirstLetter(this.stateRules.dashboardspecialdeadline)
          : ''
      })
    },
    transmitOpts () {
      if (!this.stateRules) {
        return `mail, email or fax`
      }
      switch (this.stateRules.fpcaSubmitOptionsRegister.length) {
        case 1:
          return this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict )
        case 2:
          return this.getDictWP(this.dict.E20, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase()
          })
        case 3:
          return this.getDictWP(this.dict.E21, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase(),
            item3: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[2].toLowerCase()}`, this.dict ).toLowerCase()
          })
        case 4:
          return this.getDictWP(this.dict.E22, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase(),
            item3: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[2].toLowerCase()}`, this.dict ).toLowerCase(),
            item4: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[3].toLowerCase()}`, this.dict ).toLowerCase()
          })
        default:
          return `mail, email or fax`
      }
    },
    stateRules () {
      if (this.state) {
        const scr0 = this.butterStateVotingRules
        if (scr0) {
          const scr1 = scr0.filter(x => x.stateid.toLowerCase().slice(0,2) === this.state.toLowerCase())
          return getStateRules(scr1[0], this.lang, this.$store.state.showDictionaryKeys)
        } else {
          return []
        }
      } else {
        return undefined
      }
    },
    isStateSpecial () {
      return (this.stateRules.dashboardspecial ? true : false) || (this.stateRules.dashboardspecialdeadline ? true : false)
    },
    newVoterDeadlineObject () {
      // must find a rule with a date, we cascade down until we find one
      let elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Registration')

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Request')
      }

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Return')
      }

      return this.voterDeadlineObject(elections, this.stateRules, this.dateFormat)
    },
    registeredVoterDeadlineObject () {
      // must find a rule with a date, we cascade down until we find one
      let elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Request')

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Return')
      }

      return this.voterDeadlineObject(elections, this.stateRules, this.dateFormat)
    },
    ballotReturnDeadlineObject () {
      // must find a rule with a date, we cascade down until we find one
      let elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Return')
      return this.voterDeadlineObject(elections, this.stateRules, this.dateFormat)
    },
    deadlineLanguage () {
      // 2022-03-25 per Heidi: registration==="unsure" to be treated as registration==="notregistered"
      if (this.translatedDeadlineElection.length === 0) {
        return 'There are no elections currently scheduled for '+this.getDictFLJ(`states.${this.votState}`, this.dict)+'.  Voters should send in an FPCA every calendar year.'
      } else {
        switch (this.isRegistered) {
          case 'notregistered':
            /* return this.getDictWP( this.dict.E19, this.newVoterDeadlineObject ) */
            return this.getDictWP( this.dict.N44, this.newVoterDeadlineObject )
          case 'registered':
            /* return this.getDictWP( this.dict.E23, this.registeredVoterDeadlineObject ) */
            return this.getDictWP( this.dict.N45, this.registeredVoterDeadlineObject )
          default:
            /* return this.getDictWP( this.dict.E19, this.newVoterDeadlineObject ) */
            return this.getDictWP( this.dict.N44, this.newVoterDeadlineObject )
        }
      }
    },
    isRegistered () {
      return this.currentRequestObject ? this.currentRequestObject.isRegistered : null
    },
    deadlineFormConfirmation () {
      return this.getDictWP(this.dict.E17, {leoName: this.leoName})
    },
    deadlineReceiveBallot () {
      if (this.translatedDeadlineElection.length === 0) {
        return ''
      } else {
        let daysToDeadlineElection = Math.ceil(
          (new Date(this.translatedDeadlineElection[0].electionDate).getTime() -
           getNewVfaDate().getTime()) / MS_PER_DAY)
        return this.getDictFLJ( `request.deadlineLanguage.${daysToDeadlineElection >= this.LEO_SENDOUT_BALLOTS_TRIGGER ? 'sendBallot45days' : 'sendBallotLessThan45days'}`, this.dict )
      }
    },
    deadlineBallotReturn () {
      if (this.translatedDeadlineElection.length === 0) {
        return ''
      } else {
        return this.getDictWP(this.dict.N43, this.ballotReturnDeadlineObject)
      }
    },
    user () {
      return this.$store.state.userdata.user
    },
    requests () {
      return this.$store.state.requests.requests
    },

    /** review */
    currentRequest () {
      return this.$store.state.requests.currentRequest
    },

    currentRequestObject () {
      return this.$store.getters['requests/getCurrent']
    },
    stage () {
      return this.currentRequestObject && this.currentRequestObject.status
        ? this.currentRequestObject.status
        : 'start'
    },
    name () {
      return this.user && this.user.firstName
        ? this.user.firstName
        : this.requests && this.requests[0] && this.requests[0].firstName
        ? this.requests[0].firstName
        : ''
    },
    leoName () {
      return this.currentRequestObject.leo && this.currentRequestObject.leo.n
        ? this.currentRequestObject.leo.n
        : ''
    },
    votState () {
      return this.$store.getters['requests/getCurrent'] &&
        this.$store.getters['requests/getCurrent'].leo
        ? this.$store.getters['requests/getCurrent'].leo.s
        : ''
    },
    voterRegistrationStatus () {
      return this.$store.getters['requests/getCurrent'].isRegistered || null
    },
    deadlineElection () {
      return this.$store.state.deadlineElection
    },
    deadlineElectionExists () {
      return Object.keys(this.deadlineElection).length!==0
    },
    deadlines () {
      return getDeadlines(this.deadlineElection, null, null)
    },
    translatedDeadlineElection () {
      if (Object.keys(this.deadlineElection).length===0) {
        return []
      }

      let be = JSON.parse(JSON.stringify(this.deadlineElection))
      let ve = [ ] // for legacy VFA elections format
      let reformatted

      // registration
      reformatted = reformatRules (be.rules.registration, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Registration", this.dict) 
      ve = ve.concat(reformatted)

      // ballot request
      reformatted = reformatRules (be.rules.ballotrequest, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Ballot Request", this.dict) 
      ve = ve.concat(reformatted)

      // ballot return
      reformatted = reformatRules (be.rules.ballotreturn, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Ballot Return", this.dict) 
      ve = ve.concat(reformatted)

      return ve
    },
    voterFWAB () {
      return this.deadlines.FWAB.daysToFWAB<=0
    },
    /*
    fvap_url () {
      return FVAP_URL
    },
    */
    fvap_url () {
      return this.VFAParameters.FVAP_URL
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    LEO_SENDOUT_BALLOTS_TRIGGER () {
      return this.VFAParameters.LEO_SENDOUT_BALLOTS_TRIGGER
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    ...mapGetters('requests', ['getCurrent'])
  },
}
